.login-section {
  background-color: #fff;
  padding: 50px;
  border-radius: 5px;
}

.custom-button {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.custom-button:link,
.custom-button:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  font-size: 16px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.custom-button::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
  cursor: pointer;
}

.custom-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(255, 255, 255 0.2);
}

.custom-button:hover::after {
  transform: scaleX(1.1) scaleY(1.2);
  opacity: 0;
}

.btn--blue {
  background-image: linear-gradient(to right bottom, #00c6fb 0%, #005bea 100%);
}

.btn--blue::after {
  background-image: linear-gradient(to right bottom, #00c6fb 0%, #005bea 100%);
}

.btn--orange {
  background-image: linear-gradient(to right bottom, #ffb900 0%, #ff7738 100%);
}

.btn--orange::after {
  background-image: linear-gradient(to right bottom, #ffb900 0%, #ff7738 100%);
}

.btn--red {
  background-image: linear-gradient(to right bottom, #ff0844 0%, #ffb199 100%);
}

.btn--red::after {
  background-image: linear-gradient(to right bottom, #ff0844 0%, #ffb199 100%);
}

.btn--black {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.btn--black::after {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.m-link:link,
.m-link:visited {
  margin-top: 10px;
  color: #ff7738;
  font-size: 16px;
}

.m-checkbox>span,
.m-radio>span {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: 0 0;
  position: absolute;
  top: 10px;
  left: 0;
  height: 18px;
  width: 18px;
}

.m-login__head .m-login__title {
  padding: 1px;
  letter-spacing: 15px;
  color: #2d3436 !important;
}