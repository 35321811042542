@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
body {
  background-color: #f7f7f7;
  font-family: "Open Sans", sans-serif;
}

.no-data {
  padding: 24px 0 21px;
  margin: 0 auto;
  text-align: center;
  color: #2d3436;
}

.align-center {
  text-align: center !important;
}

.icon-large {
  font-size: 100px;
  padding: 24px 0 21px;
  color: #2d3436;
}

.ptitle {
  display: table-cell;
  vertical-align: middle;
  font-size: 1.3rem;
  font-weight: 500;
  color: #2979ff;
}

.navbar-center {
  position: absolute;
  width: 100%;
  color: #fff;
  left: 0;
  font-weight: 400;
  top: 0;
  text-align: center;
  font-size: 26px;
  margin-top: 20px;
  letter-spacing: 5px;
}

.m-header {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

@media (max-width: 1024px) {
  .m-brand {
    width: 100%;
    position: relative;
    height: 65px !important;
    padding: 0 25px;
    background-color: #2d3436;
    background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
    z-index: 2;
  }
}

.m-portlet__head-text {
  color: #2d3436;
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
}

.m-subheader .m-subheader__title {
  color: #2d3436;
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item>.m-menu__heading .m-menu__link-icon, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item>.m-menu__link .m-menu__link-icon {
  color: #FB8C00;
}

.m-body .m-content {
  background-color: #f7f7f7;
}

.m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
  font-family: "Open Sans", sans-serif;
}

.text-white {
  font-family: "Open Sans", sans-serif;
  color: #FFFFFF !important;
}

.m-loader.m-loader--black:before {
  border-top-color: #2d3436
}

.bg-black {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

#toast-container>div {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

#toast-container>.alert {
  background-image: none !important;
}

#toast-container>.alert:before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  float: left;
  color: #FFF;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}

#toast-container>.alert-info:before {
  content: "\f05a";
}

#toast-container>.alert-info:before, #toast-container>.alert-info {
  color: #2196F3;
}

#toast-container>.alert-success:before {
  content: "\f00c";
}

#toast-container>.alert-success:before, #toast-container>.alert-success {
  color: #00C853;
}

#toast-container>.alert-warning:before {
  content: "\f06a";
}

#toast-container>.alert-warning:before, #toast-container>.alert-warning {
  color: #FF9100;
}

#toast-container>.alert-danger:before {
  content: "\f071";
}

#toast-container>.alert-danger:before, #toast-container>.alert-danger {
  color: #D50000;
}

.modal-page__button {
  cursor: pointer;
}

.modal-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  align-items: center;
  background-color: rgba(0, 0, 0, .8);
  transition: all 3s;
  overflow: hidden;
  backface-visibility: hidden;
  font-family: "Open Sans", sans-serif;
}

.modal-page__content-wrapper {
  background: #FFF;
  border-radius: .8rem;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  margin: 0 auto;
  position: relative;
  font-family: "Open Sans", sans-serif;
}

.modal-page .modal-page__content-wrapper .modal-page__header {
  padding: 1rem 2.5rem;
  font-family: "Open Sans", sans-serif;
}

.modal-page__header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.5rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  font-size: 16px;
  color: #2d3436;
  letter-spacing: 7px;
  font-weight: 200;
  font-family: "Open Sans", sans-serif;
}

.modal-page__content-wrapper__content {
  padding: 1.5rem 2.5rem;
  width: 100%;
}

.modal-page .modal-page__content-wrapper .modal-page__footer {
  border-top: 1px solid #e9ecef;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  padding: 1.5rem 3.3rem;
}

.modal-page .modal-page__content-wrapper .modal-page__footer .action {
  position: relative;
  margin-left: 0.625rem;
  padding: 0.625rem 1.25rem;
  border: none;
  background-color: slategray;
  border-radius: 0.25rem;
  color: white;
  font-size: 0.87rem;
  font-weight: 300;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
}

.modal-page .modal-page__content-wrapper .modal-page__footer .action:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: width 0.25s;
  z-index: 0;
}

.modal-page .modal-page__content-wrapper .modal-page__footer .action:last-child {
  background-color: #2d3436;
}

.modal-page .modal-page__content-wrapper .modal-page__footer .action:hover:before {
  width: 100%;
}

#loader {
  color: #008cff;
  height: 40px;
  left: 45%;
  position: absolute;
  top: 45%;
  width: 30%;
}

#treeparent {
  display: block;
  max-width: 350px;
  max-height: 350px;
  margin: auto;
  overflow: auto;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.custom .e-list-item.e-level-1 .e-text-content.e-icon-wrapper .e-checkbox-wrapper {
  display: none
}

.m-widget24 .m-widget24__item .m-widget24__stats {
  float: right;
  margin-right: 1.8rem;
  margin-top: 0.5rem;
  font-size: 12px !important;
  font-weight: 600;
}

.m-widget24 .m-widget24__item .m-widget24__desc {
  color: #9699a2;
  margin-right: 1.8rem;
  margin-top: -1.0rem;
  font-size: 10px;
}

.m-widget24 .m-widget24__item .m-widget24__title {
  margin-left: 1.8rem;
  margin-top: 3.21rem;
  display: inline-block;
  font-size: 14px !important;
  font-weight: 600;
}